

const Homepage = () =>{
    
    return <div className="" style={{display:'flex',alignItems:'center',margin:'auto',justifyContent:'center',flexDirection:'column'}}>
        {/* mainbodyMax sidenavBodyMax */}
        <div className="footerColumn" style={{padding:'0px',width:'100%',display:'flex',alignItems:'center'}}>
        <div className="logo"><div style={{display:'flex',margin:'auto 0px auto'}}>be</div></div>
            <div className="footerContents" style={{textAlign:'center',fontFamily:'logo',fontSize:'55px',padding:'0px',margin:'10px 0px 0px'}}>Business Exp.</div>
            <div className="footerContents" style={{lineHeight:'20px',textAlign:'center',color:'#888',margin:'0px',background:'#eee',width:'80px',padding:'5px 5px 5px',borderRadius:'5px'}}>v3.0</div>
        </div>
        

    </div>
}

export default Homepage