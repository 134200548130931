
import BizBody from '../../../businessComponents/body/bizBody'

const Body = () => {

    return <div classname="scrollbarTypeDefault" style={{border:'0px dashed RED',height:'calc(100vh - 61px)',overflowY:'auto',color:'#888'}}>
        <BizBody/>
    </div>
}

export default Body